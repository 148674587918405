import { useGetFirstImageSource } from './useGetFirstImageSource'
import type { Post } from '@store'

/**
 * 주어진 게시물에 대한 썸네일 URL을 검색
 *
 * @param {Post} post - 썸네일 URL을 검색할 게시물
 * @returns {string} - 게시물의 썸네일 URL, 또는 썸네일이 없으면 빈 문자열을 반환
 */
export const useGetPostThumbUrl = (post: Post): string => {
  switch (post.nttTyCode) {
    case 'NORM':
      return useGetFirstImageSource(post.nttCn)
    case 'CARD':
      return (post.imageOrVideoList?.images || [])[0].s3Url || ''
    case 'CVOD':
      return (
        (post.imageOrVideoList?.video || [])[0].s3ThumnailUrl?.fileUrl || ''
      )
    default:
      return ''
  }
}
